<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-top: 40px;margin-bottom:40px;font-size: 20px">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>我的作业</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table
        ref="filterTable"
        :data="tableData"
    >
      <el-table-column
          type="index"
          width="100"
          :index="indexMethod">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="作业发布日期"
          sortable
          width="200"
      >
      </el-table-column>
      <el-table-column
          prop="paperName"
          label="试卷标题"
          width="600"
      >
      </el-table-column>
      <el-table-column
          prop="statusStr"
          label="完成状态"
          width="100"
          :filters="[{ text: '未完成', value: '未完成' }, { text: '完成', value: '完成' }]"
          :filter-method="filterTag"
          filter-placement="bottom-end">
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.statusStr === '完成' ? 'primary' : 'success'"
              disable-transitions>{{ scope.row.statusStr }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="200">
        <template slot-scope="scope">

          <el-button v-if="scope.row.status == 0" @click="jumpToDetails(scope.row.paperId)" type="text" size="small">
            开始答题
          </el-button>
          <el-button v-if="scope.row.status == 1" @click="toQuestionAnswer(scope.row.examPaperAnswerId)" type="text" size="small">
            答案解析
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total"
                   style="margin-top: 20px;text-align: center">
    </el-pagination>
  </div>
</template>

<script>
import {
  checkCountByAnswerPaperAndDay,
  getMyWork
} from "@/api/index.js"

export default {
  components: {},
  data() {
    return {
      allInfo: {
        total: 0
      },
      status: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: []
    };
  },
  mounted() {
    this.listWork()
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.listWork()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.listWork()
    },
    indexMethod(index) {
      return index + 1;
    },
    filterTag(value, row) {
      return row.statusStr === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    //答案解析
    toQuestionAnswer(examPaperAnswerId) {
      let jumpRouter =this.$router.resolve({
        path: "/viewTestPaper?id=" + examPaperAnswerId
      })
      window.open(jumpRouter.href, '_blank');
      // this.$router.push({name: 'questionAnswerDetail', params: {id: examPaperAnswerId}});
    },
    //题目列表
    listWork() {
      this.loading = true
      let param = {
        teacherId: parseInt(localStorage.getItem("belongTo")),
        studentId: parseInt(localStorage.getItem("userId")),
        status: this.status,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      }
      getMyWork(param).then(res => {
        if (res.code === "0000") {
          this.allInfo = res.data
          this.tableData = res.data.list
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      });
    },
    jumpToDetails(id) {
      let routeData = this.$router.resolve({
        path: "/testPaperDetails?id=" + id
      });
      window.open(routeData.href, '_blank');
    }
  }
}
;
</script>

